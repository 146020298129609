.hero {
    background: url(/public/assets/images/target.jpg) no-repeat center;
    /* background-size: cover; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    
    background-attachment: fixed;
    position: relative;
    z-index: 0;
}

.hero:before {
    content: '';
    background: rgba(7, 10, 12, 0.72);
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
}